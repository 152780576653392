import React, { type CSSProperties, type ForwardedRef } from "react"

export interface RatioProps {
  className?: string
  preset?: string
  styleContainer?: CSSProperties
  style?: CSSProperties
  ratio?: string
  children?: JSX.Element | ((style: CSSProperties) => JSX.Element)
}

function RatioForwarded(
  { ratio, className, style = {}, styleContainer = {}, children }: RatioProps,
  ref?: ForwardedRef<HTMLDivElement>
) {
  const componentStyle: CSSProperties = {
    position: "relative",
  }

  const containerStyle: CSSProperties = {
    width: "100%",
    position: "relative",
  }

  const childrenStyle: CSSProperties = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  }

  return (
    <div data-comp="Abstracts/Ratio" ref={ref} className={className} style={{ ...componentStyle, ...style }}>
      <div className={ratio} style={{ ...containerStyle, ...styleContainer }}>
        {typeof children === "function" ? children?.(childrenStyle) : children}
      </div>
    </div>
  )
}

export const Ratio = React.forwardRef<HTMLDivElement, RatioProps>(RatioForwarded)
