import path from "path"
import { createUrl, PathRegExp } from "@marvinh/path-to-regexp"
import pathsMapping from "config/paths-mapping.json"

import type { Nullish, ParseUrlParams } from "~/@types/generics"
import { DEFAULT_CMS_LANG, DEFAULT_LOCALE } from "~/lib/i18n/constants"
import type { I18nLocale } from "~/lib/i18n/types"
import { getAllDimensions } from "~/lib/i18n/utils/get-all-dimensions"
import { getCMSLang } from "~/lib/i18n/utils/get-i18n"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import { isLocale } from "~/lib/i18n/utils/is-locale"
import { getPropertyFromGID } from "~/lib/shopify/utils/id"
import { SB_PAGES, type SbPagesComponents } from "~/lib/storyblok/constants"
import type { SbLinkFromSchema, ShopifyLinkFromSchema } from "~/components/ui/Link/_data/schema"
import { isSbLink, isShopifyLink } from "~/components/ui/Link/utils/is-link"
import { objectKeys } from "~/utils/object-keys"

export function hrefResolver(link: SbLinkFromSchema | ShopifyLinkFromSchema, locale: Nullish<string>) {
  const processedLocale = getProcessedLocale(locale)
  if (isShopifyLink(link)) {
    if (!link?.id || !link?.handle) return null

    const property = getPropertyFromGID(link?.id)

    switch (property) {
      case "Product":
        return getPath(processedLocale, "products/:slug", { slug: link?.handle })
      case "Collection":
        return getPath(processedLocale, "collections/:slug", { slug: link?.handle })
      default:
        return null
    }
  }

  if (isSbLink(link)) {
    if (!link.story) {
      return null
    }

    return resolveSbStory(link, locale)
  }
}

function removeDimensionFromSlug(slug?: Nullish<string>) {
  if (!slug) return ""
  const splitedSlug = slug.split("/")
  const [computedDimension] = splitedSlug

  if (!computedDimension) return slug

  if (getAllDimensions().includes(computedDimension)) {
    return splitedSlug.slice(1).join("/")
  }

  return slug
}

export function getPath<Route extends string, Params extends ParseUrlParams<Route>>(
  locale: Nullish<string>,
  route: Route,
  params: Params
) {
  const processedLocale = isLocale(locale) ? locale : DEFAULT_LOCALE

  const processedRoute =
    (pathsMapping as Record<string, Record<string, { source: string; destination: string }>>)?.[route]?.[
      processedLocale
    ]?.source ?? route

  const pathRegExp = new PathRegExp(processedRoute)
  const endPath = createUrl(pathRegExp, params)

  return path.join("/", processedLocale, endPath).replace(/\/$/, "")
}

export function resolveSbStory(link: SbLinkFromSchema, locale: Nullish<string>): string | null {
  const story = link.story

  const processedLocale = getProcessedLocale(locale)
  const storySlug = getLocalizedStorySlug(link, processedLocale)

  const pageComponent = getStoryComponent(link, processedLocale)

  let slug
  switch (pageComponent) {
    case "home_page":
      return getPath(processedLocale, "/", {})
    case "universal_page":
      slug = storySlug?.path?.replace(SB_PAGES[pageComponent].rootSlug, "") ?? story?.slug
      return getPath(processedLocale, "/:slug", { slug: removeDimensionFromSlug(slug) })
    case "configurator_page":
      slug = storySlug?.path?.replace(SB_PAGES[pageComponent].rootSlug, "") ?? story?.slug
      return getPath(processedLocale, "/configurator/:slug", { slug: removeDimensionFromSlug(slug) })
    case "authentication_page":
      return getPath(locale, "/account/:slug", { slug: removeDimensionFromSlug(story?.slug) })
    case "addresses_page":
      return getPath(locale, "/account/addresses", {})
    case "account_informations_page":
      return getPath(locale, "/account", {})
    case "orders_page":
      return getPath(locale, "/account/orders", {})
    case "legals_page":
      slug = storySlug?.path?.replace(SB_PAGES[pageComponent].rootSlug, "") ?? story?.slug
      return getPath(locale, "/legals/:slug", { slug: removeDimensionFromSlug(slug) })
    case "faq_page":
      slug = storySlug?.path?.replace(SB_PAGES[pageComponent].rootSlug, "") ?? story?.slug
      return getPath(locale, "/faq/:slug", { slug: removeDimensionFromSlug(slug) })
    case "search_page":
      return getPath(locale, "/search", {})
    case "contact_page":
      return getPath(locale, "/contact", {})
    default:
      return null
  }
}

function getStoryComponent(link: SbLinkFromSchema, locale: Nullish<I18nLocale>) {
  if (link?.story?.content?.component) {
    return link?.story?.content?.component
  }

  if (!link?.story?.full_slug) return null
  const slugWithoutLang = removeLangFromSlug(link?.story?.full_slug, locale)
  if (!slugWithoutLang) return null
  const slug = removeDimensionFromSlug(slugWithoutLang)

  if (!slug) return null

  return findComponentFromFullSlug(slug)
}

function removeLangFromSlug(slug: Nullish<string>, locale: Nullish<I18nLocale>) {
  const cmsLang = getCMSLang(locale)
  return cmsLang !== DEFAULT_CMS_LANG ? slug?.replace(`${cmsLang}/`, "") : slug
}

function getLocalizedStorySlug(link: SbLinkFromSchema, locale: Nullish<I18nLocale>) {
  if (link?.story?.content) {
    const cmsLang = getCMSLang(locale)
    return (
      link?.story?.translated_slugs?.find((slug) => slug?.lang === cmsLang) ?? {
        path:
          link?.story?.default_full_slug ?? removeDimensionFromSlug(removeLangFromSlug(link?.story?.full_slug, locale)),
      }
    )
  }

  return { path: removeDimensionFromSlug(removeLangFromSlug(link?.story?.full_slug, locale)) }
}

/**
 * Finds the most relevant component key from a cached URL.
 *
 * This function iterates over the keys of the SB_PAGE_STORIES object and checks if the cached URL starts with the rootSlug value of each key.
 * It keeps track of the key with the longest matching rootSlug and the deepest path (most slashes).
 *
 * @param {string} fullSlug - The URL to match against the rootSlug values.
 * @returns {PagesComponent | undefined} - The most relevant key or undefined if no match is found.
 */
export function findComponentFromFullSlug(fullSlug: string) {
  // Get all keys from the SB_PAGE_STORIES object
  const keys = objectKeys(SB_PAGES)

  // Initialize variables to keep track of the most relevant key, maximum match length, and maximum depth
  let mostRelevantKey: SbPagesComponents | undefined
  let maxMatchLength = 0
  let maxDepth = 0

  // Iterate over each key in the SB_PAGE_STORIES object
  for (const key of keys) {
    // Get the rootSlug value for the current key
    const value = SB_PAGES[key].slug.replace(":slug", "")

    // Check if the cached URL starts with the current rootSlug value
    if (fullSlug.startsWith(value)) {
      // Calculate the length of the matching part
      const matchLength = value.length
      // Calculate the depth of the current rootSlug by counting the number of slashes
      const depth = (value.match(/\//g) || []).length

      // Update the most relevant key if the current rootSlug has a greater depth
      // or if it has the same depth but a longer match length
      if (depth > maxDepth || (depth === maxDepth && matchLength > maxMatchLength)) {
        mostRelevantKey = SB_PAGES[key].sbPagesComponent
        maxMatchLength = matchLength
        maxDepth = depth
      }
    }
  }

  // Return the most relevant key or undefined if no match is found
  return mostRelevantKey
}
