import type { GetUnionByKey, ObjectValues } from "~/@types/generics"
import type { SbFolder } from "~/lib/storyblok/types"

export const sbPagesComponent = [
  "universal_page",
  "signin_page",
  "enable_account",
  "register_page",
  "reset_password",
  "forgot_password",
  "home_page",
  "size_guides",
  "orders_page",
  "addresses_page",
  "account_informations_page",
  "collection_page",
  "authentication_page",
  "contact_page",
  "configurator_page",
  "legals_page",
  "faq_page",
  "search_page",
  "redirect_page",
  "error_page",
] as const

const sbGlobalsComponent = [
  "dictionary",
  "metas",
  "navigation",
  "back_in_stock",
  "footer",
  "reinsurance_slider",
  "product_settings",
  "collection_settings",
  "collection_tree",
  "cart",
  "card_tag",
  "internal_redirects",
  "newsletter_popin",
  "banner",
  "size_guides",
] as const

const sbAuthenticationSlugs = {
  sign_in: "login",
  register: "register",
  reset_password: "reset-password",
  forgot_password: "forgot-password",
  enable_account: "enable-account",
} as const

const sbAccountSlugs = {
  orders: "my-orders",
  addresses: "my-addresses",
} as const

const sbFoldersComponent = ["globals", "globals/size_guides", "globals/card-tag", "pages"] as const

export type SbSitemapPagesComponent = (typeof SB_SITEMAP_REPEATABLE_PAGES)[number]["sbPagesComponent"]

export type SbAuthenticationSlugs = (typeof sbAuthenticationSlugs)[keyof typeof sbAuthenticationSlugs]
export type SbPagesComponent = (typeof sbPagesComponent)[number]
export type SbGlobalsComponent = (typeof sbGlobalsComponent)[number]
export type SbFolderComponent = (typeof sbFoldersComponent)[number]

export const SB_AUTHENTICATION_PAGES = {
  signin_page: {
    slug: `pages/${sbAuthenticationSlugs.sign_in}`,
    sbPagesComponent: "authentication_page",
  },
  register_page: {
    slug: `pages/${sbAuthenticationSlugs.register}`,
    sbPagesComponent: "authentication_page",
  },
  reset_password: {
    slug: `pages/${sbAuthenticationSlugs.reset_password}`,
    sbPagesComponent: "authentication_page",
  },
  forgot_password: {
    slug: `pages/${sbAuthenticationSlugs.forgot_password}`,
    sbPagesComponent: "authentication_page",
  },
  enable_account: {
    slug: `pages/${sbAuthenticationSlugs.enable_account}`,
    sbPagesComponent: "authentication_page",
  },
} as const

export const SB_ACCOUNT_PAGES = {
  account_informations_page: {
    slug: `pages/account`,
    sbPagesComponent: "account_informations_page",
  },
  addresses_page: {
    slug: `pages/account/${sbAccountSlugs.addresses}`,
    sbPagesComponent: "addresses_page",
  },
  orders_page: {
    slug: `pages/account/${sbAccountSlugs.orders}`,
    sbPagesComponent: "orders_page",
  },
} as const

export const SB_NAVIGATIONS = {
  legals_navigation: {
    slug: "globals/legals-navigation",
  },
  faqs_navigation: {
    slug: "globals/faqs-navigation",
  },
  account_navigation: {
    slug: "globals/account-navigation",
  },
} as const

export const SB_PAGES = {
  universal_page: {
    slug: "pages/universals/:slug",
    rootSlug: "pages/universals/",
    sbPagesComponent: "universal_page",
  },
  configurator_page: {
    slug: "pages/configurators/:slug",
    rootSlug: "pages/configurators/",
    sbPagesComponent: "configurator_page",
  },
  contact_page: {
    slug: "pages/contact",
    sbPagesComponent: "contact_page",
  },
  error_page: {
    slug: "pages/error",
    sbPagesComponent: "error_page",
  },
  home_page: {
    slug: "pages/",
    sbPagesComponent: "home_page",
  },
  collection_page: {
    slug: "pages/collection/:slug",
    rootSlug: "pages/collection/",
    sbPagesComponent: "collection_page",
  },
  legals_page: {
    slug: "pages/legals/:slug",
    rootSlug: "pages/legals/",
    sbPagesComponent: "legals_page",
  },
  faq_page: {
    slug: "pages/faq/:slug",
    rootSlug: "pages/faq/",
    sbPagesComponent: "faq_page",
  },
  size_guides: {
    slug: "pages/size_guides",
    sbPagesComponent: undefined,
  },
  search_page: {
    slug: "pages/search",
    sbPagesComponent: "search_page",
  },
  redirect_page: {
    slug: "pages/redirects/:slug",
    sbPagesComponent: "redirect_page",
  },
  ...SB_AUTHENTICATION_PAGES,
  ...SB_ACCOUNT_PAGES,
} satisfies Partial<SbFolder<"pages", SbPagesComponent>>

export const SB_SITEMAP_REPEATABLE_PAGES = [SB_PAGES.universal_page, SB_PAGES.legals_page, SB_PAGES.faq_page] as const

export const SB_REPEATABLES_TYPES = [SB_PAGES.universal_page]

export const SB_FOLDERS = {
  "globals/size_guides": {
    slug: "globals/size_guides",
  },
  "globals/card-tag": {
    slug: "globals/card-tag",
  },
  globals: {
    slug: "globals/",
  },
  pages: {
    slug: "pages/",
  },
} as const satisfies Record<SbFolderComponent, { slug: string }>

export const SB_GLOBALS = {
  size_guides: {
    slug: "globals/size_guides",
  },
  metas: {
    slug: "globals/metas",
  },
  banner: {
    slug: "globals/banner",
  },
  dictionary: {
    slug: "globals/dictionary",
  },
  navigation: {
    slug: "globals/navigation",
  },
  collection_tree: {
    slug: "globals/collection-tree",
  },
  cart: {
    slug: "globals/cart",
  },
  back_in_stock: {
    slug: "globals/back-in-stock",
  },
  footer: {
    slug: "globals/footer",
  },
  reinsurance_slider: {
    slug: "globals/reinsurance_slider",
  },
  product_settings: {
    slug: "globals/product_page_settings",
  },
  collection_settings: {
    slug: "globals/collection_page_settings",
  },
  card_tag: {
    slug: "globals/card-tag/:slug",
  },
  internal_redirects: {
    slug: "globals/internal-redirects",
  },
  newsletter_popin: {
    slug: "globals/newsletter-popin",
  },
  ...SB_NAVIGATIONS,
} satisfies SbFolder<"globals", SbGlobalsComponent>

type SbStories = ObjectValues<typeof SB_PAGES> | ObjectValues<typeof SB_GLOBALS>
type SbAllowedFolder = ObjectValues<typeof SB_FOLDERS>

export type SbSlug = GetUnionByKey<SbStories, "slug">
export type SbPagesSlug = GetUnionByKey<ObjectValues<typeof SB_PAGES>, "slug">
export type SbPagesComponents = GetUnionByKey<ObjectValues<typeof SB_PAGES>, "sbPagesComponent">
export type SbNavigationsSlugs = GetUnionByKey<ObjectValues<typeof SB_NAVIGATIONS>, "slug">
export type SbAuthenticationPagesSlug = GetUnionByKey<ObjectValues<typeof SB_AUTHENTICATION_PAGES>, "slug">

export type SbAllowedFolderPath = GetUnionByKey<SbAllowedFolder, "slug">
